#kt_header {
	border-bottom: solid 1px #CCCCCC;
	background-color: #FFFFFF;

	*:not(i) {
		font-family: 'Kumbh Sans';
		font-weight: 400;
	}

	.kt-header__topbar-user * {
		font-size: 14px !important;
	}

	.header-breadcrumb {
		display: flex;
		align-items: center;
		width: fit-content;
		padding-left: 16px;

		p {
			font-weight: 600;
			font-size: 16px;
			margin-bottom: 0px;
		}

		p.breadcrumb-current-page {
			color: #222222;
		}

		p.breadcrumb-path {
			color: #999999;
			margin-right: 16px;
		}
	}

	@media screen and (max-width: 1024px) {
		display: none;
	}
}

#kt_header_mobile {
	border-bottom: solid 1px #CCCCCC;
	background-color: #FFFFFF;
	height: 72px;

	*:not(i) {
		font-family: 'Kumbh Sans';
		font-weight: 400;
	}

	.left-container {
		display: flex;
		align-items: center;

		i {
			color: #284EEA;
			font-size: 20px;
		}
	}

	.header-breadcrumb {
		display: flex;
		align-items: center;
		width: fit-content;
		padding-left: 16px;

		p {
			font-weight: 600;
			font-size: 16px;
			margin-bottom: 0px;
		}

		p.breadcrumb-current-page {
			color: #222222;
		}

		p.breadcrumb-path {
			color: #999999;
			margin-right: 16px;
		}
	}
}

.header-profile-menu {
	width: 300px;
	padding: 16px !important;
	border-radius: 8px !important;

	h3 {
		font-size: 16px;
		font-weight: 400;
		color: #222222;
		margin: 0;
	}

	h4 {
		font-size: 14px;
		font-weight: 400;
		color: #777777;
		margin-top: 4px;
		margin-bottom: 16px;
	}

	div.signout {
		display: flex;
		align-items: center;
	}
}