@import "@fontsource/kumbh-sans";

#kt_aside *:not(i) {
	font-family: 'Kumbh Sans';
	font-size: 16px;
	line-height: 24px;
}

#kt_aside {
	border: solid 1px #CCCCCC;
	overflow-x: clip;

	.kt-aside-menu {
		margin: 0px;
	}
	
	div.sidemenu-simpletext-v2 {
		padding: 14px 22px;
		font-weight: 500;
		font-size: 14px;
		color: #666666;
	}

	#kt_aside_menu ul.kt-menu__nav>li {
		a {
			padding: 14px 22px;
		}

		.kt-menu__item .kt-menu__item a {
			padding-left: 64px;
		}

		.kt-menu__subnav a {
			padding-left: 48px;
		}
	}
}

.sidemenu-icon-v2 {
	stroke: #666666;
}

.sidemenu-icon-v2-fill {
	fill: #666666;
}

.sidemenu-right-icon-v2 {
	stroke: #284EEA;
}

.kt-menu__item--open .sidemenu-icon-v2,
.kt-menu__item--here .sidemenu-icon-v2 {
	stroke: #284EEA;
}

.kt-menu__item--open .sidemenu-icon-v2-fill,
.kt-menu__item--here .sidemenu-icon-v2-fill {
	fill: #284EEA;
}