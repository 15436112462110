#kt_footer {
	padding-block: 29px !important;
	min-height: 80px;
	height: fit-content;

	*:not(i) {
		font-family: 'Kumbh Sans';
	}

	div.kt-container {
		padding-inline: 24px !important;
	}

	div.footer-container {
		display: flex;
		justify-content: space-between;
		width: 100%;

		div {
			align-items: center;
		}

		div.footer-copyright,
		div.footer-copyright > a {
			color: #555555;
			font-weight: 400;
			font-size: 14px;
		}
		
		div.footer-links {
			display: flex;
			flex-wrap: wrap;
			margin-left: 4px;

			a {
				color: #335BFF;
				font-size: 14px;
			}

			a:not(:last-child) {
				margin-right: 24px;
			}
		}
	}

	a:hover {
		color: #1E3699 !important;
	}

	@media screen and (max-width: 767px) {
		border-top: 1px solid #CCCCCC;

		div.footer-container {
			display: block;

			div.footer-links>a {
				width: 50%;
				margin: 0px !important;
			}
		}
	}
}