//
// Layout Config
//



// Layout
$kt-aside-default-width: 280px;
$kt-aside-minimize-width: 70px;
$kt-aside-offcanvas-width: 255px;


// Layout Skins
$kt-layout-skins: (
	dark: #1e1e2d,
	light: #F7F7F7
);

// content bg color
$kt-content-bg-color: #FFFFFF;

// Border Radius
$kt-border-radius: 4px;

// Page padding
$kt-page-padding: (
	desktop: 25px,
	mobile: 15px
);

// Page container width
$kt-page-container-width: 1380px;
